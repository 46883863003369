import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { IconBroadcastOff } from '@tabler/icons-react';
import { UserContext } from 'context/UserContext';
import config from 'globalConfig';
import useOnlineStatus from 'hooks/isOnline';
import ErrorScreen from 'ui-component/ErrorScreen';
// import { GET_ROUTE } from 'utils/queries';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, menuIsOpen, route }) => ({
    ...theme.typography.mainContent,
    overflowX: 'hidden',
    marginTop: '3rem',
    background: theme.palette.background.paper,
    minHeight: 'calc(100vh - 3rem)'
}));

// ==============================|| MAIN LAYOUT ||============================== //

const AuthLayout = () => {
    const theme = useTheme();
    const route = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    // const { adminid } = useParams();
    // const { data, error, loading } = useQuery(GET_ROUTE, { variables: { id: adminid } });
    const isOnline = useOnlineStatus();

    // Handle compressed navbar
    const [isCompressed, setCompressed] = useState(false);
    // const [socials, setSocials] = useState(null);
    const [userContext, setUserContext] = useState(UserContext);
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const navigate = useNavigate();
    const isDev = process.env.NODE_ENV === 'development';

    const verifyUser = useCallback(() => {
        if (isOnline) {
            fetch(isDev ? `${config.remoteDevServer}/api/users/refresh` : `${process.env.PUBLIC_URL}/api/users/refresh`, {
                method: 'POST',
                credentials: 'include',
                // Pass authentication token as bearer token in header
                headers: {
                    'Content-Type': 'application/json'
                    // Authorization: `Bearer ${responseData.token}`
                }
            })
                .then(async (response) => {
                    if (response && response.ok) {
                        try {
                            const data = await response.json();
                            setUserContext((oldValues) => ({ ...oldValues, token: data.token }));

                            // call refreshToken every 5 minutes to renew the authentication token.
                            // setTimeout(verifyUser, 5 * 60 * 1000);
                            // console.log('verifyUser', userContext);
                            // setTimeout(verifyUser, 5 * 60 * 1000);
                        } catch (err) {
                            // console.log('ERR', err);
                        }
                    } else if (response && response.status === 401) {
                        // setUserContext((oldValues) => ({ ...oldValues, token: null }));
                        // console.log('401', response);
                    } else {
                        // setUserContext((oldValues) => ({ ...oldValues, token: null }));
                    }
                })
                .catch((err) => {
                    // siilently drop the error
                    console.log();
                    // setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));
                });
        }
    }, [setUserContext, isDev, isOnline]);

    useEffect(() => {
        if (!userContext.token) {
            verifyUser();
        }
    }, [setUserContext, userContext.token, verifyUser]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                border: 'none !important',
                width: '100%'
            }}
        >
            <CssBaseline />

            <Main open={leftDrawerOpened} route={route}>
                {!isOnline ? (
                    <ErrorScreen
                        icon={<IconBroadcastOff color={theme.palette.error.main} stroke={2} size={72} />}
                        title="Sei offline"
                        subtitle="Per utilizzare quest'applicazione devi essere online. Controlla la tua connessione e riprova."
                        errbg={theme.palette.grey[50]}
                        loadingfg={theme.palette.primary[400]}
                        errfg={theme.palette.error.main}
                        loadingbg={theme.palette.grey[50]}
                        details={false}
                        err={{
                            message: 'test'
                        }}
                        top="3rem"
                    />
                ) : (
                    // <Box
                    //     sx={{
                    //         height: '3.5rem',
                    //         width: '3.5rem',
                    //         aspectRatio: '1',
                    //         // ml: '1rem',
                    //         display: 'flex',
                    //         alignItems: 'center',
                    //         justifyContent: 'center',
                    //         // boxSizing: 'content-box',
                    //         background: theme.palette.error.lighter,
                    //         borderLeft: `1px solid ${theme.palette.primary.light}`,
                    //         borderBottom: `1px solid ${theme.palette.primary.light}`,
                    //         borderTop: `1px solid ${theme.palette.primary.light}`
                    //     }}
                    // >
                    //     <IconBroadcastOff color={theme.palette.error.main} stroke={2} size={24} />
                    // </Box>
                    <Outlet context={[userContext, setUserContext]} />
                )}
            </Main>
        </Box>
    );
};

export default AuthLayout;
