import { UserContext } from 'context/UserContext';
import config from 'globalConfig';
import { useCallback, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Outlet, useNavigate } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //
const UserVerify = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [cookies, setCookies] = useCookies(['refreshToken']);
    const navigate = useNavigate();
    const isDev = process.env.NODE_ENV === 'development';

    const logoutUser = () => {
        window.localStorage.setItem('logout', Date.now());

        setUserContext((oldValues) => ({
            ...oldValues,
            token: null,
            user: null,
            route: null
        }));

        setCookies('refreshToken', null, { path: '/' });
        navigate(`/login`, { replace: true });
    };
    // check if token has changed
    let oldToken = cookies.refreshToken;
    const verifyUser = useCallback(() => {
        fetch(isDev ? `${config.remoteDevServer}/api/users/get` : `${process.env.PUBLIC_URL}/api/users/get`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(async (response, error) => {
                if (response && response.ok) {
                    const responseData = await response.json();
                    // console.log(responseData);
                    if (responseData && responseData.token) {
                        if (oldToken !== responseData.token) {
                            setCookies('refreshToken', responseData.token, { path: '/' });
                        }
                    } else {
                        logoutUser();
                    }
                    // setUserContext((oldValues) => ({ ...oldValues, token: responseData.token }));
                    // if (data && data.token) {
                    if (!userContext.user && responseData.token) {
                        try {
                            fetch(isDev ? `${config.remoteDevServer}/api/users/me` : `${process.env.PUBLIC_URL}/api/users/me`, {
                                method: 'POST',
                                credentials: 'include',
                                // Pass authentication token as bearer token in header
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${responseData.token}`
                                }
                            })
                                .then(async (response) => {
                                    if (response.ok) {
                                        let meData = await response.json();
                                        // console.log('MEDATA', meData);
                                        if (!meData) {
                                            // setUserContext((oldVals) => ({ ...oldVals, token: null, user: null, route: null }));
                                            logoutUser();
                                            // clearTimeout(verifyUser);
                                        } else {
                                            if (meData && meData.identity) {
                                                if (
                                                    meData?.identity?.Ruolo?.interno &&
                                                    !meData.identity.Lavoratore &&
                                                    !meData.identity.UtenteCliente_Utente_ud_utentecliToUtenteCliente
                                                ) {
                                                    // admin
                                                    meData = {
                                                        ...meData
                                                    };
                                                } else if (
                                                    meData.identity.Lavoratore &&
                                                    !meData.identity.UtenteCliente_Utente_ud_utentecliToUtenteCliente
                                                ) {
                                                    meData = {
                                                        ...meData,
                                                        client: meData.identity.Lavoratore.ud_cliente,
                                                        department: meData.identity.Lavoratore.ud_reparto,
                                                        site: meData.identity.Lavoratore.ud_sede
                                                    };
                                                    delete meData.identity.Lavoratore;
                                                } else if (
                                                    meData.identity.UtenteCliente_Utente_ud_utentecliToUtenteCliente &&
                                                    !meData.identity.Lavoratore
                                                ) {
                                                    if (
                                                        meData?.identity?.Ruolo.cd_ruolo === 'DTL' ||
                                                        meData?.identity?.Ruolo.cd_ruolo === 'SGR' ||
                                                        meData?.identity?.Ruolo.cd_ruolo === 'RGD'
                                                    ) {
                                                        console.log('DTL');
                                                        const utenteCliRef =
                                                            meData.identity.UtenteCliente_Utente_ud_utentecliToUtenteCliente
                                                                ?.UtenteClienteRef_UtenteClienteRef_ud_utentecliToUtenteCliente;
                                                        console.log(utenteCliRef);
                                                        const clients = utenteCliRef.map((client) => ({
                                                            ud_cliente: client.ud_cliente,
                                                            departments: client?.Reparto?.map((dep) => ({
                                                                ud_reparto: dep.ud_reparto
                                                            }))
                                                        }));
                                                        // flatten all ud_cliente and ud_reparto

                                                        const departments = clients.reduce((acc, curr) => {
                                                            curr?.departments?.forEach((dep) => {
                                                                acc.push(dep);
                                                            });
                                                            return acc;
                                                        }, []);

                                                        meData = {
                                                            ...meData,
                                                            clients,
                                                            departments
                                                        };
                                                        console.log('medata', meData);
                                                    } else if (meData?.identity?.Ruolo.cd_ruolo === 'EDR') {
                                                        // console.log('EDR');
                                                        // console.log('MEDATA', meData);
                                                        const deps =
                                                            meData.identity.UtenteCliente_Utente_ud_utentecliToUtenteCliente
                                                                .Reparto_Reparto_ud_espertorpToUtenteCliente;
                                                        // departments have each a ud_reparto and ud_cliente. group by ud_cliente
                                                        const groupedDepartments = deps.reduce((acc, curr) => {
                                                            if (!acc[curr.ud_reparto]) {
                                                                acc[curr.ud_reparto] = [];
                                                            }
                                                            acc[curr.ud_reparto].push(curr);
                                                            return acc;
                                                        }, {});

                                                        const departments = Object.keys(groupedDepartments).map((key) => ({
                                                            ud_reparto: key
                                                        }));
                                                        const clients = Object.keys(groupedDepartments).map((key) => ({
                                                            ud_cliente: groupedDepartments[key][0].ud_cliente,
                                                            departments: groupedDepartments[key].map((dep) => ({
                                                                ud_reparto: dep.ud_reparto
                                                            }))
                                                        }));
                                                        // console.log('MEDATA', meData);
                                                        // console.log('DEPS', deps);
                                                        // console.log('DEPARTMENTS', departments);
                                                        // console.log('CLIENTS', clients);

                                                        meData = {
                                                            ...meData,
                                                            clients,
                                                            departments
                                                        };
                                                    } else if (meData?.identity?.Ruolo.cd_ruolo === 'MDA') {
                                                        const deps =
                                                            meData.identity.UtenteCliente_Utente_ud_utentecliToUtenteCliente
                                                                .Reparto_Reparto_ud_medicoautorizzatoToUtenteCliente;
                                                        // departments have each a ud_reparto and ud_cliente. group by ud_cliente
                                                        const groupedDepartments = deps.reduce((acc, curr) => {
                                                            if (!acc[curr.ud_reparto]) {
                                                                acc[curr.ud_reparto] = [];
                                                            }
                                                            acc[curr.ud_reparto].push(curr);
                                                            return acc;
                                                        }, {});

                                                        const departments = Object.keys(groupedDepartments).map((key) => ({
                                                            ud_reparto: key
                                                        }));
                                                        const clients = Object.keys(groupedDepartments).map((key) => ({
                                                            ud_cliente: groupedDepartments[key][0].ud_cliente,
                                                            departments: groupedDepartments[key].map((dep) => ({
                                                                ud_reparto: dep.ud_reparto
                                                            }))
                                                        }));
                                                        meData = {
                                                            ...meData,
                                                            clients,
                                                            departments
                                                        };
                                                    }
                                                    delete meData.identity.UtenteCliente_Utente_ud_utentecliToUtenteCliente;
                                                } else {
                                                    logoutUser();
                                                }
                                                setUserContext((oldVals) => ({
                                                    ...oldVals,
                                                    user: meData
                                                }));
                                            } else {
                                                logoutUser();
                                            }
                                        }
                                    } else if (
                                        (response && response.status === 500) ||
                                        (response && response.status === 401) ||
                                        (response && response.status === 404)
                                    ) {
                                        logoutUser();
                                    } else {
                                        logoutUser();
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                } else if ((response && response.status === 500) || (response && response.status === 401)) {
                    logoutUser();
                } else {
                    logoutUser();
                }

                return response;
            })
            .catch((err) => {
                // console.log(err);
            });
    }, [setUserContext, navigate, userContext, isDev]);

    useEffect(() => {
        verifyUser();
    }, [userContext.token]);
    return verifyUser;
};
const ContainerLayout = () => {
    UserVerify();

    return <Outlet />;
};
export default ContainerLayout;
